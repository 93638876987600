/** @format */

import * as React from 'react';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface AuiInputProps extends BaseTextFieldProps {
  name: string;
  label?: string;
  control?: Control<any>;
  defaultValue?: string;
  shrink?: boolean;
  error: any;
}

const AuiInput: React.FC<AuiInputProps> = (props) => {
  const {
    name,
    control,
    error,
    label,
    defaultValue = '',
    ...otherProps
  } = props;
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value, name: fieldName, onBlur } }) => (
        <TextField
          key={fieldName}
          fullWidth
          label={label}
          color='secondary'
          onBlur={onBlur}
          onChange={onChange}
          error={Boolean(error)}
          helperText={error?.message}
          name={fieldName}
          value={value}
          {...otherProps}
        />
      )}
    />
  );
};

export default AuiInput;
