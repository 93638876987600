/** @format */

// Routes paths
export class RoutePath {
	// Base paths
	public static readonly ROOT = '/';
	public static readonly APP = '/app';
	public static readonly AUTH = '/auth';
	public static readonly COMPANY = RoutePath.APP + '/company';
	public static readonly DASHBOARD = RoutePath.APP + '/dashboard';
	public static readonly BILLING = RoutePath.APP + '/billing';
	public static readonly EMPLOYEES = RoutePath.APP + '/employees';
	public static readonly PLANNING = RoutePath.APP + '/planning';
	public static readonly CUSTOM = RoutePath.APP + '/custom';
	public static readonly MODULES = RoutePath.APP + '/modules';
	public static readonly ACTIVE_MODULES = RoutePath.APP + '/active_modules';
	public static readonly CHAT = RoutePath.APP + '/chat';
	public static readonly PRESENCES = RoutePath.APP + '/teams';

	// Invoice paths
	public static readonly INVOICES = RoutePath.APP + '/invoices';
	public static readonly PREVIEW_INVOICE = 'detail/:invoiceId';
	public static readonly EDIT_INVOICE = 'edit/:invoiceId';
	public static readonly NEW_INVOICE = 'new';

	// Authentication paths
	public static readonly LOGIN = '/auth/login';
	public static readonly SIGNUP = '/auth/signup';
	public static readonly FORGOT_PASSWORD = '/forgot_password';
	public static readonly RESET_PASSWORD = '/reset_password';

	// Team paths
	public static readonly ADD_MEMBER = 'add';
	public static readonly CREATE_TEAM = 'create_team';
	public static readonly VIEW_TEAM = 'detail/:teamId';
	public static readonly EDIT_TEAM = 'edit/:teamId';
}

// Storage keys
export class StorageKey {
	public static readonly MODE = 'mode';
	public static readonly LANGUAGE = 'lng';
	public static readonly SIDEBAR = 'sidebar';
	public static readonly FAVORITE_MODULE = 'fav_module';
	public static readonly FILTER_MODULE = 'filter_module';
	public static readonly MODULE = 'module';
	public static readonly SESSION = 'session';
	public static readonly ACCESS_TOKEN = 'access_token';
	public static readonly ACCENT = 'accent';
}

// Timing constants
export class Delay {
	public static readonly DEBOUNCE = 300;
	public static readonly THROTTLE = 300;
	public static readonly LOADING = 500;
	public static readonly SNACKBAR = 3000;
	public static readonly SMS_CODE = 60;
}

// API endpoints
export class Endpoint {
	public static readonly RANDOM_IMAGE = 'https://randomuser.me/api/?inc=picture&noinfo';
	public static readonly BASE = 'http://128.199.200.229:3001/api/v1/orchestrator/company/';
	public static readonly BASE_TEST = 'http://128.199.200.229:3001/api/v1/orchestrator/user/';
	public static readonly DOMAIN = '';

	// Company endpoints
	public static readonly CREATE_COMPANY = 'create';
	public static readonly LOGIN = 'login';
	public static readonly COMPANIES = 'companies';
	public static readonly ADD_EMPLOYEE = 'employee/add';
	public static readonly FETCH_EMPLOYEES = ':id/employees';

	// Team endpoints
	public static readonly FETCH_TEAMS = ':id/teams';
	public static readonly FETCH_TEAM = 'team/:id';
	public static readonly ADD_TEAM = 'team/add';
	public static readonly UPDATE_TEAM = 'team/:id/update';
	public static readonly DELETE_TEAM = 'team/:id/delete';
}
